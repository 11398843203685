<template>
  <v-container
    class="pt-9"
    style="height: 100%;"
  >
    <FileManagerCard />
  </v-container>
</template>

<script>
import FileManagerCard from '@/components/FileManager/FileManagerCard'

export default {
  name: 'FileMgr',

  components: {
    FileManagerCard
  }
}
</script>

<style lang="scss" scoped></style>
