<template>
  <v-card
    class="filemgr-card"
    elevation="0"
    color="transparent"
  >
    <v-row
      ref="content"
      class="panel-row flex-nowrap"
      no-gutters
    >
      <v-col
        class="panel-col d-none d-sm-block"
        style="flex-basis: 350px;"
      >
        <FileTree />
      </v-col>
      <v-col
        class="panel-col ml-sm-3"
        style="flex-basis: 1143px;"
      >
        <FileViewer />
      </v-col>
      <v-col
        class="panel-col ml-3 d-none d-sm-block"
        style="flex-basis: 330px;"
      >
        <FileInfo />
      </v-col>
    </v-row>
    <div
      ref="actions"
      class="filemgr-card-actions"
    >
      <slot name="actions" />
    </div>
  </v-card>
</template>

<script>
import FileTree from './Panels/FileTree'
import FileViewer from './Panels/FileViewer'
import FileInfo from './Panels/FileInfo'

export default {
  name: 'FileManagerCard',

  components: {
    FileTree,
    FileViewer,
    FileInfo
  },

  props: {
    maxWidth: {
      type: [String, Number],
      default: 1440
    }
  },

  data: () => ({
    timerDetectHeight: null
  }),

  mounted() {
    this.setContentMaxHeight()
  },

  beforeDestroy() {
    this.$store.dispatch('files/init')
    clearTimeout(this.timerDetectHeight)
  },

  methods: {
    setContentMaxHeight() {
      const content = this.$refs.content
      const actions = this.$refs.actions

      if (content && actions) {
        const actionsHeight = actions?.getBoundingClientRect?.()?.height

        if (actionsHeight && !Number.isNaN(actionsHeight)) {
          content.style.height = `calc(100% - ${actionsHeight}px)`
        } else {
          content.style.height = '100%'
        }
      }

      clearTimeout(this.timerDetectHeight)
      this.timerDetectHeight = setTimeout(this.setContentMaxHeight, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.filemgr-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.panel-row {
  flex-grow: 1;
  height: 100%;
}

.panel-col {
  overflow-x: auto;
  height: 100%;
}

.filemgr-card-actions {
  flex-grow: 0;
}
</style>
